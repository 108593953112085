@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: 'Barlow', sans-serif;
}

* {
  padding: 0;
  margin: 0;
}
